<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">

      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Training Requests
            </h1>
          </div>

          <div class="pa-5 d-flex">
            <v-row no-gutters>
              <v-col cols="6">
                <v-row no-gutters>
                <v-col cols="6 d-flex mt-auto mb-auto">
                <v-select v-model="action" class="mr-5 action-select" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0"   label="Actions" return-object outlined dense/>
              </v-col>
               <v-col cols="6 d-flex mt-auto mb-auto">
                 <div class="submit-button-container">
                   <Button
                    :label="'Submit'"
                    :btnType="'Submit'"
                    @onClick="approveBulkSelection"
                    :isLoading="loading"
                    class=""
                  />
                 </div>
               </v-col>
              </v-row>
              </v-col>
            </v-row>
          </div>
          <v-data-table
              loading-text="Loading data. Please wait...."
              :loading="approveLoading"
              :headers= 'headers'
              :items="pendingTraining"
               show-select
              :single-select="false"
              v-model="selected"
              item-key="requestId"
             >
                <template v-slot:item.request.employee.firstName="{ item }" >
                  {{item.request.employee.firstName}}, {{item.request.employee.lastName}} {{item.request.employee.middleName}}
                </template>
                <template v-slot:item.request.startDate="{ item }" >
                  {{item.request.startDate | formatDate}}
                </template>
                <template v-slot:item.request.resumptionDate="{ item }" >
                  {{item.request.resumptionDate | formatDate}}
              </template>
                <template v-slot:item.actions="{ item }" >
                    <div class="d-flex">
                      <Button :btnType="'Submit'" :color="'info'" :label="'View'" @onClick="viewDetail(item)" class="mr-2"/>
                      <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="openConfirmDialog(item)" class="mr-2"/>
                      <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" class="mr-2" />
                      <Button :btnType="'Submit'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
                    </div>
              </template>
          </v-data-table>

          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField  v-model="formData.comments" :label="'Comment'"/>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                  :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :disabled="!valid"
                  class="mr-4"
                />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Dialog ref="detailView" :title="'Training Request Detail'" :width="600">
            <v-row v-if="requestDetail !== null">
              <div class="row text-left">
                <div class="col-md-6">Training Type</div>
                <div class="col-md-6"><b>{{requestDetail.trainingType}}</b></div>
                <div class="col-md-6">Personnel Name</div>
                <div class="col-md-6"><b>{{requestDetail.personnelName}}</b></div>
                <div class="col-md-6">Training Name</div>
                <div class="col-md-6"><b>{{requestDetail.trainingName}}</b></div>
                <div class="col-md-6">Comment</div>
                <div class="col-md-6"><b>{{requestDetail.comment}}</b></div>
                <div class="col-md-6">Amount</div>
                <div class="col-md-6"><b>{{requestDetail.amount}}</b></div>
                <div class="col-md-6">Request Date</div>
                <div class="col-md-6"><b>{{requestDetail.requestDate}}</b></div>
                <div class="col-md-6">Start Date</div>
                <div class="col-md-6"><b>{{requestDetail.startDate}}</b></div>
                <div class="col-md-6">Resumption Date</div>
                <div class="col-md-6"><b>{{requestDetail.endDate}}</b></div>
              </div>
            </v-row>
            <v-divider></v-divider>
            <!-- Logs-->
            <v-row>
              <v-card width="600" elevation="0">
                <v-tabs v-model="tab">
                  <v-tab href="#tab-2">Log</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-2">
                    <v-card class="pt-2" flat>
                      <div v-if="loadingLogs" class="text-center">
                        <v-progress-circular indeterminate
                                             color="primary"></v-progress-circular>
                      </div>
                      <v-data-table v-else
                                    :headers='logHeaders'
                                    :items="logs">
                        <template v-slot:item.activityDate="{ item }">
                          <span>{{ item.activityDate | formatDate }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-row>
            <!--End Logs-->

            <template v-slot:footer>
              <!-- <Button :btnType="'Close'" :label="'Cancel'" @onClick="closeallowanceViewModal" /> -->
            </template>
          </Dialog>
        </v-container>
      </div>
    </div>

  <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @commentPer="getComment" :mType="'forward'"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
       <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
     </PersonnelSearch>

  <ConfirmationDialog :title="'Are you sure you want to approve?'" :isLoading="approveLoading"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>
import {
  APPROVE_REQUEST,
  APPROVE_BULK_TRAINING,
  FETCH_PENDING_TRAINING,
  FORWARD_REQUEST
} from '@/store/action-type'
import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import moment from 'moment'
import { requestService } from '@/services'

export default {
  components: {
    Button,
    Dialog,
    TextField,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      loading: false,
      loadingLogs: false,
      dialog: false,
      approveLoading: false,
      rejectDialog: false,
      valid: false,
      action: '',
      bulk: false,
      selected: [],
      personnelSearch: false,      
      DelegateName: null,
      DelegateEmail: null,
      comment: '',
      forwarding: false,
      formData: {
        comments: ''
      },
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }],
      item: null,
      requestDetail: null,
      headers: [
        {
          text: 'Name',
          value: 'request.employee.firstName'
        },
        {
          text: 'Start Date',
          value: 'request.startDate'
        },
        {
          text: 'Resumption Date',
          value: 'request.resumptionDate'
        },
        {
          text: 'Amount',
          value: 'amount'
        },
        {
          text: 'Comment',
          value: 'request.comments'
        },
        {
          text: 'Added By',
          value: 'request.addedBy',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ],
      logs: [],
      tab: null,
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comment'
        },
        {
          text: 'Date',
          value: 'activityDate'
        }

      ]
    }
  },
  computed: {
    pendingTraining () {
      return this.$store.getters.pendingTraining
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    viewDetail (item) {
      console.clear()
      console.log(item)
      const { request } = item
      const detail = {
        personnelName: `${request.employee.firstName}, ${request.employee.lastName} ${request.employee.middleName}`,
        trainingName: request.requestType.name,
        comment: item.comments,
        amount: item.amount,
        requestDate: moment(request.dateAdded).format('MM-DD-yyyy'),
        startDate: moment(request.startDate).format('MM-DD-yyyy'),
        endDate: moment(request.resumptionDate).format('MM-DD-yyyy'),
        trainingType: item.trainingType.name
      }
      this.requestDetail = detail
      this.$refs.detailView.openModal()

      console.log('Items', item)
      this.loadingLogs = true
      requestService.trainingApprovalLogs(item.requestGuid).then(({ data }) => {
        console.log('This is Data', data)

        this.logs = data.logs === null ? [] : data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
    },
    openConfirmDialog (item) {
      this.dialog = true
      this.item = item
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    approve () {
      this.approveLoading = true
      // this.item.status = 1
      // this.item.type = 'Training'
      // this.item.id = this.item.requestId
      let data = []
      data.push({
        id: this.item.requestId,
        Status: 1,
        Comments: ''
      })

      this.$store
        .dispatch(APPROVE_BULK_TRAINING, data)
        .then((result) => {
          this.showAlertMessage('Approve Request successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approve Request failed', 'error')
        }).finally(() => {
          this.dialog = false
          this.item = null
          this.approveLoading = false
        })
    },
    approveBulkSelection () {
      if (this.action.id === 2 && this.formData.comments === '') {
        this.rejectDialog = true
        this.bulk = true
        return
      }
      let bulkItems = []

      this.selected.forEach(element => {
        bulkItems.push({ id: element.requestId, Status: this.action.id, Comments: this.action.id === 1 ? '' : this.formData.comments })
      })

      this.rejectDialog = false
      this.loading = true

      this.$store.dispatch(APPROVE_BULK_TRAINING, bulkItems).then((result) => {
        this.showAlertMessage(`${this.action.name} Requests successful`, 'success')
        // eslint-disable-next-line handle-callback-err
      })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage(`${this.action.name}  Requests failed`, 'error')
        }).finally(() => {
          this.loading = false
          bulkItems = []
          this.formData.comments = ''
          this.selected = []
        })
    },
    openCancelModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    closeCancelModal () {
      this.rejectDialog = false
      this.item = null
    },
    reject (item) {
      if (this.bulk === true) {
        this.approveBulkSelection()
        return
      }
      item.status = 2
      this.$store
        .dispatch(APPROVE_REQUEST, item)
        .then((result) => {
          this.showAlertMessage('Reject Training successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Reject Training failed', 'error')
        })
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name      
    },
    getComment (comment) {
      this.comment = comment
    },
    getPendingTraining () {
      this.approveLoading = true
      this.$store
        .dispatch(FETCH_PENDING_TRAINING)
        .then(() => {
          this.approveLoading = false
        })
        .catch((error) => {
          if (error) {
            this.approveLoading = false
          }
        })
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        itemId: this.item.request.employeeId,
        // "ItemId":428,//This should be the Employee Id        
        'DelegateName': this.DelegateName,
        'DelegateEmail': this.DelegateEmail,
        'Comment': this.comment
      }

      this.$store.dispatch(FORWARD_REQUEST, { data, type: 'Training' }).then((res) => {
        this.showAlertMessage('Forwarded successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Failed to Forward try again', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })

      console.log('forward data', data)
    }
  },
  mounted () {
    this.getPendingTraining()
    // this.$store
    //   .dispatch(FETCH_PENDING_TRAINING)
    //   .then(() => {})
    //   .catch((error) => {
    //     console.log(error)
    //     if (error) {
    //       this.showAlertMessage('There are no items pending your approval', 'warning')
    //     }
    //   })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.action-select {
 padding-top: 24px;
}
.small-button {
    font-size: 11px !important;
    height: 25px !important;
}
</style>
